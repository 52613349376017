import React from "react";
import * as Immutable from "immutable";

import TextField from "material-ui/TextField";
import Checkbox from "material-ui/Checkbox";
import ImmutableSelect from "js/components/immutable-select";
import NumberField from "js/components/number-field";
import FormattedJsonDataField from "js/components/formatted-json-data-field";

const spacer = {marginRight: "1rem"};

export default React.memo(({prop, width = 200, value, onChange, disabled, options = Immutable.List()}) => {
  const label = prop.get("label", "");
  const multi = prop.get("multi", false);
  switch (prop.get("type")) {
    case "boolean":
      return <Checkbox
        disabled={disabled}
        title={prop.get("name", "n/a")}
        style={{marginRight: "1rem", marginTop: "2rem", width: `calc(${width} - 1rem)`}}
        label={label}
        checked={value || false}
        onCheck={(_, x) => onChange(x)} />;
    case "enum":
      return <PropSelect
        disabled={disabled}
        title={prop.get("name", "n/a")}
        placeholder={label}
        width={width}
        value={value || (multi ? Immutable.Set() : null)}
        onChange={onChange}
        multi={multi}
        options={prop.get("options", Immutable.List())} />;
    case "runtime-list":
      return <PropSelect
          disabled={disabled}
          placeholder={label}
          width={width}
          value={value || (multi ? Immutable.Set() : null)}
          onChange={onChange}
          multi={multi}
          options={options}
          newValuesAllowed={true} />;
    case "integer":
      return <NumberField
        disabled={disabled}
        title={prop.get("name", "n/a")}
        style={{marginRight: "1rem", width: `calc(${width} - 1rem)`}}
        floatingLabelText={label}
        value={(value || value === 0) ? value : ""}
        onChange={onChange} />;
    case "float":
      return <NumberField
          disabled={disabled}
          title={prop.get("name", "n/a")}
          style={{marginRight: "1rem", width: `calc(${width} - 1rem)`}}
          floatingLabelText={label}
          value={(value || value === 0) ? value : ""}
          allowFloats={true}
          onChange={onChange} />;
    case "json-str":
      return <TextField
        disabled={disabled}
        title={prop.get("name", "n/a")}
        floatingLabelText={label}
        style={spacer}
        fullWidth={true}
        multiLine={true}
        value={value || ""}
        onChange={e => onChange(e.target.value)}
        errorText={validateJson(value)} />;
    case "long-text":
      return <TextField
        disabled={disabled}
        title={prop.get("name", "n/a")}
        floatingLabelText={label}
        style={spacer}
        fullWidth={true}
        multiLine={true}
        value={value || ""}
        onChange={e => onChange(e.target.value)} />;
    case "json-data":
      return <FormattedJsonDataField
        disabled={disabled}
        title={prop.get("name", "n/a")}
        floatingLabelText={label}
        style={spacer}
        value={value}
        onChange={onChange} />;
    case "prompt":
      return <TextField
        disabled={disabled}
        title={prop.get("name", "n/a")}
        style={{marginRight: "1rem", width: `calc(${width} - 1rem)`}}
        floatingLabelText={label}
        value={value || ""}
        onClick={() => {
          const newValue = prompt("Enter " + label, value);
          const validator = prop.get("validator");
          if (newValue) {
            let error = validator && validator(newValue, value);
            if (error) {
              alert(error);
            } else {
              onChange(newValue);
            }
          }
        }} />
    default:
      return <TextField
        disabled={disabled}
        title={prop.get("name", "n/a")}
        style={{marginRight: "1rem", width: `calc(${width} - 1rem)`}}
        floatingLabelText={label}
        value={value || ""}
        onChange={e => onChange(e.target.value)} />;
  }
});

const PropSelect = React.memo(({width, title, placeholder, value, onChange, disabled, multi, options, newValuesAllowed}) => {
  return (
      <div
        title={title || placeholder}
        style={{
          width: `calc(${width} - 1rem)`,
          marginTop: "0.5rem",
          marginRight: "1rem"
        }}>
        <span style={{color: "#bbb", fontSize: "12px", fontFamily: "Roboto,sans-serif"}}>{placeholder}</span>
        <div style={{marginTop: 3}}>
          <ImmutableSelect
            options={options}
            multi={multi}
            searchable={true}
            clearable={true}
            disabled={disabled}
            selectedValueOrValues={value}
            newValuesAllowed={newValuesAllowed}
            onChange={onChange} />
        </div>
      </div>);
});

const validateJson = str => {
  if (!str) {
    return null;
  } else {
    try {
      JSON.parse(str);
      return null;
    } catch (e) {
      return e.message || "Invalid JSON";
    }
  }
}
